<template>
  <v-sheet>
    <v-sheet class="d-flex justify-end align-center">
      <v-select solo style="max-width: 200px;"
        class="mr-3"
        v-model="season_id"
        outlined
        dense
        :items="seasons"
        item-text="season_name"
        hide-details
        item-value="id"
        label="Kausi"
        :loading="loading_seasons"
        @change="changeSeason($event)"
      ></v-select>
      <v-btn
        @click="addingTeam = true; updateVideoUrl()" 
        outlined
      >
        <v-icon>mdi-plus</v-icon>
        Add game
      </v-btn>
    </v-sheet>
    <div class="mt-10">
      <v-sheet v-if="loading_games" class="d-flex justify-center">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-sheet>
      <GameCard
        v-for="game in games" 
        :key="game.id + 'game'"
        :game="game"
        @edit="startEditing(game)"
      ></GameCard>
    </div>
    <edit-game-dialog
      :show="addingTeam"
      :league="league"
      @close="addingTeam = false; resetGame()"
      v-model="newGame"
      @save="startSaveGame()"
    ></edit-game-dialog>
    <div style="height: 100px;"></div>
  </v-sheet>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import GameCard from '@/components/tiimi_admin/league/game/GameCard'
import EditGameDialog from './game/EditGameDialog.vue'
export default {
  created() {
    this.initSeasons(this.$route.params.league_id)
    this.initGames({ league_id: this.$route.params.league_id })
      .then(e => {
        this.season_id = e.season_id
      })
  },
  data: () => ({
    season_id: null,
    addingTeam: false,
    twoHoursInMillis: 1000 * 60 * 60 * 2,
    newGame: {
      home_team_id: null,
      away_team_id: null,
      shown_live: false,
      game_info: '',
      game_error: '',
      score_home: 0,
      score_away: 0,
      will_be_analyzed: true,
      starttime_unix: null,
      publishtime_unix: null,
      video_url: '',
      video_live_url: '',
      video_type: 'hls',
      external_service_id: '',
      season_id: null,
      done_analysis_state: null
    },
    reset: {
      home_team_id: null,
      away_team_id: null,
      shown_live: false,
      game_info: '',
      game_error: '',
      score_home: 0,
      score_away: 0,
      will_be_analyzed: true,
      starttime_unix: null,
      publishtime_unix: null,
      video_url: '',
      video_live_url: '',
      video_type: 'hls',
      external_service_id: '',
      season_id: null,
      done_analysis_state: null
    }
  }),
  components: {
    GameCard,
    EditGameDialog
  },
  computed: {
    ...mapGetters('admin', [
      'leagueById',
      'leagues',
      'games',
      'loading_games',
      'seasons',
      'loading_seasons'
    ]),
    league() {
      return this.leagueById(this.$route.params.league_id)
    }
  },
  methods: {
    ...mapActions('admin', [
      'saveGame',
      'editGame',
      'initGames',
      'initSeasons'
    ]),
    ...mapActions('noti', [
      'error',
      'success'
    ]),
    resetGame() {
      this.newGame = this.reset
    },
    changeSeason(season_id) {
      this.season_id = season_id
      this.initGames({ league_id: this.$route.params.league_id, season_id })
    },
    updateVideoUrl() {
      this.newGame.video_url = this.leagueById(this.$route.params.id)?.default_video_url
    },
    startEditing(game) {
      this.newGame = game
      this.addingTeam = true
    },
    startSaveGame() {
      let game = {
        ...this.newGame,
        league_id: this.$route.params.league_id
      }

      if(!this.newGame.id) {
        this.saveGame(game)
          .then(() => {
            this.success('Game added :)')
            this.clearGame()
            this.addingTeam = false
            this.resetGame()
          })
          .catch(e => {
            this.error(e)
          })
      } else {
        this.editGame(game)
          .then(() => {
            this.success('Game edited')
            this.clearGame()
            this.addingTeam = false
            this.resetGame()
          })
          .catch(e => {
            this.error(e)
          })
      }
    },
    clearGame() {
      this.newGame = this.reset
    }
  }
}
</script>