<template>
  <v-dialog @input="$event ? '' : $emit('decline');" :value="show" max-width="400">
    <v-card>
      <v-card-title>
        {{ text }}
      </v-card-title>
      <v-card-text>
        {{ subText }}
      </v-card-text>
      <v-card-actions>
        <v-btn @click="$emit('decline')">
          {{ $t('cancel') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :color="btnColor" @click="$emit('accept')">
          {{ btnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['show', 'text', 'btnColor', 'btnText', 'subText']
}
</script>